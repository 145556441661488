import { hydrateWhenVisible } from 'vue-lazy-hydration';
import { MediaLoading } from '@/node_modules/@osp/design-system/types/media';
import { IClsOptimizationMixin } from '@/node_modules/@osp/design-system/components/mixins/cls-optimization-mixin';
import { ImageOptimizationSettings } from '@/node_modules/@osp/design-system/components/Media/Media.props';
import { importRunTask } from '@/node_modules/@osp/design-system/assets/js/utilities/dynamicImports';
import {
	ILcpOptimizationMixin,
	LcpOptimizationMixin,
} from '@/node_modules/@osp/design-system/components/mixins/lcp-optimization-mixin';
import { ClsOptimizationMixin } from '../mixins/cls-optimization-mixin';
import { Bool, Component, Mixins, Prop, Vue } from '@/app-utils/decorators';
import { ContentSlot as ContentSlotData } from '@/generated/hybris-raml-api';
import IntersectingComponent from '@/components/atoms/intersecting-component';
import { useCmsContentStore } from '~/@api/store/cmsContentApi';
import { useUserStore } from '~/@api/store/userApi';
import { CmsContentEntryReference } from '~/@api/store.types';
import { importLogger } from '~/app-utils/dynamic-imports';

const IMAGE_PRELOAD_SECTIONS = 1;

const CLS_SUPPORTED_COMPONENTS = ['SectionLayout', 'IntersectingComponent'];

@Component({
	components: {
		IntersectingComponent,
		// Async components
		BarComponent: hydrateWhenVisible(() => import('@/components/molecules/bar/bar.vue')),
		GridLayoutComponent: hydrateWhenVisible(() => import('./content/grid-layout/grid-layout.vue')),
		SectionLayout: hydrateWhenVisible(
			() => import('@/components/section-layout/section-layout.vue'),
		),
		LinkBar: hydrateWhenVisible(
			() => import('@/components/molecules/content/link-bar/link-bar.vue'),
		),
		Messagebox: hydrateWhenVisible(
			() => import('~/components/molecules/messagebox/messagebox.vue'),
		),
		UspBar: hydrateWhenVisible(
			() => import('@/node_modules/@osp/design-system/components/UspBar/UspBar.vue'),
		),
	},
	mixins: [ClsOptimizationMixin, LcpOptimizationMixin],
})
export default class ContentSlot extends Mixins<
	Vue & IClsOptimizationMixin & ILcpOptimizationMixin
>(Vue) {
	private componentPreloadCountData = 0;

	@Prop()
	public id: string;

	@Prop()
	public data: ContentSlotData;

	@Prop({ required: false })
	public references: {};

	@Prop()
	public flexGrow;

	@Prop({ default: 0 })
	public componentPreloadCount: number;

	@Prop({ default: 0 })
	public imagePreloadCount: number;

	@Prop({ default: false })
	public preloadLink: boolean;

	@Prop({ default: undefined })
	public contentPreviewImageOptimization: boolean;

	@Prop({ default: undefined })
	public contentPreviewImageSettings: ImageOptimizationSettings;

	@Prop({ default: false })
	public holdsLcp: boolean;

	@Bool()
	public disableIntersectingComponent: boolean;

	public clsPreparedComponents = {};

	public clsUpdateUID(): void {
		this.clsUID = this.id;
	}

	get content(): CmsContentEntryReference[] | undefined {
		if (this.id) {
			return useCmsContentStore(this.$store).api.slot(this.id);
		} else if (this.data && this.data.components) {
			return this.data.components as CmsContentEntryReference[];
		}

		return undefined;
	}

	get gender() {
		return useUserStore(this.$store).state.user?.gender?.code;
	}

	public created() {
		// Preloaded was only, if navigation was not from within SPA
		this.componentPreloadCountData = this.isNewVisitor ? this.componentPreloadCount : 0;

		// Activate all in case of fallback timer triggers this
		// Special implementation needed due to dynamic created intersecting content implementations
		this.$on('clsActivateAll', () => {
			Object.keys(this.clsPreparedComponents).forEach((_, clsKey) => {
				this.clsShowRefElement(`${clsKey}`, true);
			});
		});
	}

	public render(h) {
		if (!this.content || this.content.length === 0) {
			(this as IClsOptimizationMixin).doSkipClsOptimization();
		}
		if (!this.content && this.flexGrow) {
			return h('div', { style: { flexGrow: this.flexGrow } });
		} else if (this.content) {
			let componentContent: any[];

			if (this.references && Object.keys(this.references).length > 0) {
				componentContent = this.content
					.map((reference) => ({
						component: reference.key.split('__')[0],
						props: {
							content: this.references[reference.key],
							id: reference.key,
						},
					}))
					.filter((dereferenced) => !!dereferenced.props.content);
			} else {
				const { api: cmsContentApi } = useCmsContentStore(this.$store);

				componentContent = this.content
					// Reference key (e.g. "OspMegaDropDownComponent__8797240067132")
					// is dereferenced to get the real object/reference
					.map((reference) => {
						const dereferenced = cmsContentApi.dereference(reference);

						if (!dereferenced) {
							importLogger().then(({ default: logger }) => {
								logger.debug(
									`Could not dereference ${reference.key}. Maybe its not a vue component?`,
								);
							});
						}

						return dereferenced;
					})
					.filter((dereferenced) => !!dereferenced);
			}

			const mapped = this.getMappedComponentContent(componentContent, h);

			if (mapped.length === 0) {
				this.$emit('noContent');
			}

			return h('div', {}, mapped);
		}
	}

	getRenderedComponent(h, dereferenced, index) {
		if (this.isUseIntersectingComponent(index)) {
			return this.withIntersectingComponent(
				h,
				this.createComponent(h, dereferenced, index, true),
				index,
			);
		}

		return this.createComponent(h, dereferenced, index, false);
	}

	private getMappedComponentContent(componentContent: any[], h: any) {
		return componentContent.map((dereferenced, index) => {
			const renderedComponent = this.getRenderedComponent(h, dereferenced, index);
			const isSectionLayoutWithVisibleChild =
				renderedComponent.data?.attrs?.component === 'SectionLayout' &&
				renderedComponent?.data?.attrs?.content?.rows?.length > 0;
			const isIntersectionSectionLayoutWithVisibleChild =
				renderedComponent.tag?.includes('IntersectingComponent') &&
				renderedComponent.componentOptions?.children.some(
					(child) =>
						(child.tag.includes('SectionLayout') && child.data.attrs.rows.length > 0) ||
						child.tag.includes('GridLayout'),
				);

			if (isSectionLayoutWithVisibleChild || isIntersectionSectionLayoutWithVisibleChild) {
				const clsKey = this.getSectionItemClsKey(index);

				if (!(clsKey in this.clsPreparedComponents)) {
					this.clsPreparedComponents[this.getSectionItemClsKey(index)] =
						index === 0 || index < this.componentPreloadCountData;
				}
			}

			return renderedComponent;
		});
	}

	private createComponent(h, dereferenced, index, isWrapped = false) {
		const componentClsKey = this.getSectionItemClsKey(index, isWrapped);
		// - Components can appear multiple times on a page, so we add the index to the key
		// - Components can have additional or other data for different genders,
		// so we added the gender to the key

		return h(dereferenced.component, {
			key: `${this.gender}-${dereferenced.id}-${index}`,
			attrs: this.getAttrs(dereferenced, index, componentClsKey),
			style: {
				display: isWrapped || index < this.componentPreloadCount ? 'block' : 'none',
				visibility:
					isWrapped || index < this.componentPreloadCount || this.isClsReady(componentClsKey)
						? undefined
						: 'hidden',
			},
			on: this.getComponentHooks(dereferenced, componentClsKey, isWrapped),
			ref: componentClsKey,
		});
	}

	private getComponentHooks(
		dereferenced: any,
		componentClsKey: string,
		isWrapped: boolean,
	): { [key: string]: any } {
		return {
			'hook:created': () => {
				// If cls state not registered but content rows extist for this component, post add cls state
				if (
					!(componentClsKey in this.clsPreparedComponents) &&
					dereferenced.content?.rows?.length > 0
				) {
					this.clsPreparedComponents[componentClsKey] = false;
				}
			},
			'hook:mounted': () => {
				// Do not execute when component got clsPreparationFinished listener
				if ((this.$refs[componentClsKey] as any).$listeners?.clsPreparationFinished) return;

				// If mounted is due to no clsPreparationFinished listener exists, register as prepared when mounted
				this.clsRegisterDynamicPrepared(componentClsKey);

				if (!CLS_SUPPORTED_COMPONENTS.includes(dereferenced.component)) {
					// If component is wrapped (e.g. by intersection component), trigger clsPreparationFinished to wrapper
					this.$emit('clsPreparationFinished');
				}
			},
			clsPreparationFinished: () => {
				importRunTask().then(({ runTask }) => {
					runTask(() => {
						this.clsRegisterDynamicPrepared(componentClsKey);

						if (isWrapped) {
							this.$emit('clsPreparationFinished');
						}

						if (process.client) {
							this.clsShowNextComponent(componentClsKey);
						}
					});
				});
			},
		};
	}

	private isUseIntersectingComponent(index: number): boolean {
		return index >= this.componentPreloadCountData;
	}

	private getAttrs(dereferenced: any, index: number, componentClsKey: string) {
		const localPreviewImageOptimization =
			this.contentPreviewImageOptimization ?? this.previewImageOptimization;

		return {
			...(dereferenced.content || {}),
			...(dereferenced?.id && dereferenced?.content ? dereferenced : {}),
			...(dereferenced.props || {}),
			imageLoading: index >= IMAGE_PRELOAD_SECTIONS ? MediaLoading.LAZY : MediaLoading.EAGER,
			preloadLink: this.preloadLink,
			imagePreloadCount: this.imagePreloadCount,
			preRendered: index < this.componentPreloadCountData,
			componentPreviewImageOptimization: localPreviewImageOptimization,
			componentPreviewImageSettings: localPreviewImageOptimization
				? this.contentPreviewImageSettings
				: undefined,
			holdsLcp: this.holdsLcp,
			cls: componentClsKey,
		};
	}

	private withIntersectingComponent(h, component, index) {
		const componentClsKey = this.getSectionItemClsKey(index);

		return h(
			IntersectingComponent,
			{
				props: {
					disabled: this.disableIntersectingComponent,
					clsComponentKeys: [component.data?.ref],
					cls: componentClsKey,
				},
				style: {
					display:
						this.disableIntersectingComponent || component.data.style.display === 'block'
							? 'block'
							: 'none',
					visibility:
						index === 0 ||
						component?.data?.attrs?.content?.rows?.length === 0 ||
						(this.clsPreparedComponents[componentClsKey] === true &&
							this.isClsReady(componentClsKey))
							? undefined
							: 'hidden',
				},
				on: {
					rendering: () => {
						// Ensure wrapped element will be shown after it was rendered and if it is CLS ready
						this.clsRegisterDynamicPrepared(componentClsKey);
					},
					clsPreparationFinished: () => {
						// Set status for current
						this.clsRegisterDynamicPrepared(componentClsKey);
					},
				},
				ref: componentClsKey,
			},
			[component],
		);
	}

	private isClsReady(componentClsKey: string): boolean {
		const prevClsKey = this.getPreviousClsDynamicComponentKey(componentClsKey);
		return process.server || (prevClsKey && this.clsPreparedComponents[prevClsKey] === true);
	}

	private getPreviousClsDynamicComponentKey(componentClsKey: string): string | undefined {
		const clsKeys = Object.keys(this.clsPreparedComponents).filter(
			(k) => k.indexOf('sectionItem_') === 0,
		);
		const clsDynamicComponentsIndex = clsKeys.indexOf(componentClsKey);

		if (clsDynamicComponentsIndex > 0) {
			return clsKeys[clsDynamicComponentsIndex - 1];
		}

		return undefined;
	}

	private getNextClsDynamicComponentKey(componentClsKey, onlyInactive = false): string | undefined {
		let clsKeys = Object.keys(this.clsPreparedComponents).filter(
			(k) => k.indexOf('sectionItem_') === 0,
		);

		if (onlyInactive) {
			clsKeys = clsKeys.filter((k) => this.clsPreparedComponents[k] === false);
			return clsKeys.length ? clsKeys[0] : undefined;
		}

		const clsDynamicComponentsIndex = clsKeys.indexOf(componentClsKey);

		if (clsDynamicComponentsIndex + 1 < clsKeys.length) {
			return clsKeys[clsDynamicComponentsIndex + 1];
		}

		return undefined;
	}

	private clsShowNextComponent(componentClsKey: string): void {
		// Get next component key to activate
		const nextKey = this.getNextClsDynamicComponentKey(componentClsKey, true);
		if (nextKey) {
			this.clsShowRefElementWithFinishCheck(nextKey);
		}
	}

	private clsShowRefElementWithFinishCheck(componentClsKey: string): void {
		if (componentClsKey) {
			(this as IClsOptimizationMixin).clsShowRefElement(componentClsKey, true);
		}
		if (Object.values(this.clsPreparedComponents).map((state) => state === false).length === 0) {
			// If all dynamic components have cls state true, set state in main cls registry
			(this as IClsOptimizationMixin).clsRegisterPrepared('IteratedContent');
		}
	}

	private getSectionItemClsKey(index: number, wrapped = false): string {
		return `sectionItem_${index + 1000}` + (wrapped ? '_wrapped' : '');
	}

	private clsRegisterDynamicPrepared(clsKey: string) {
		importRunTask().then(({ runTask }) => {
			runTask(() => {
				// Register current one as prepared
				this.clsRegisterPrepared(clsKey, true);
				this.clsShowRefElement(clsKey, true);

				// If previous cls key false, activate previous
				const clsPrevKey = this.getPreviousClsDynamicComponentKey(clsKey);
				if (this.clsPreparedComponents[clsPrevKey] === false) {
					this.clsActivateInactive(clsKey);
				}

				if (this.clsCheckAllComponentsPrepared()) {
					// If all components have cls state true, finalize
					this.clsFinalize();
					this.clsCheckForVisibilityWatcher();
				} else {
					// ... otherwise start to display the next element
					this.clsShowRefElement(this.getNextClsDynamicComponentKey(clsKey), true);
				}
			});
		});
	}

	private clsActivateInactive(clsStopKey: string | undefined = undefined) {
		const clsDynamicKeys = Object.keys(this.clsPreparedComponents).filter(
			(k) => k.indexOf('sectionItem_') === 0,
		);

		const BreakException = {};

		try {
			clsDynamicKeys.forEach((clsKeyToCheck, index) => {
				if (clsStopKey && clsKeyToCheck === clsStopKey) {
					throw BreakException;
				}

				if (
					this.clsPreparedComponents[clsKeyToCheck] === false &&
					this.clsPreparedComponents[clsDynamicKeys[index - 1]] === true
				) {
					this.clsShowRefElement(clsKeyToCheck, true);
					this.clsRegisterPrepared(clsKeyToCheck, true);
				}
			});
		} catch (e) {
			if (e !== BreakException) throw e;
		}
	}
}
